<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        A compound was found to contain
        <latex-number :number="pctCarbon" />
        % carbon,
        <latex-number :number="pctHydrogen" />
        % hydrogen, and
        <latex-number :number="pctOxygen" />
        % oxygen. Determine the empirical formula for this compound by entering the number of each
        atom in the formula.
      </p>

      <calculation-input
        v-model="inputs.nCarbons"
        prepend-text="$\text{C:}$"
        append-text="$\text{atoms}$"
        group-size="sm"
        :disabled="!allowEditing"
        aria-label="Carbon"
        dense
        class="mb-2"
      />

      <calculation-input
        v-model="inputs.nHydrogens"
        prepend-text="$\text{H:}$"
        append-text="$\text{atoms}$"
        group-size="sm"
        :disabled="!allowEditing"
        aria-label="Hydrogen"
        dense
        class="mb-2"
      />

      <calculation-input
        v-model="inputs.nOxygens"
        prepend-text="$\text{O:}$"
        append-text="$\text{atoms}$"
        group-size="sm"
        :disabled="!allowEditing"
        aria-label="Oxygen"
        dense
        class="mb-2"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import LatexNumber from '../displayers/LatexNumber';
import CalculationInput from '../inputs/CalculationInput';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput';

const PERCENTAGES = [
  {C: '59.96', H: '13.42', O: '26.62'},
  {C: '54.53', H: '9.15', O: '36.32'},
  {C: '62.04', H: '10.41', O: '27.55'},
  {C: '41.39', H: '3.47', O: '55.14'},
  {C: '66.63', H: '11.18', O: '22.19'},
  {C: '48.64', H: '8.16', O: '43.20'},
];

export default {
  name: 'Question66',
  components: {
    CalculationInput,
    LatexNumber,
    ChemicalNotationInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        nCarbons: null,
        nHydrogens: null,
        nOxygens: null,
      },
    };
  },
  computed: {
    moleculeNumber() {
      return this.taskState.getValueBySymbol('moleculeVariable').content.value - 1;
    },
    pctCarbon() {
      return PERCENTAGES[this.moleculeNumber].C;
    },
    pctHydrogen() {
      return PERCENTAGES[this.moleculeNumber].H;
    },
    pctOxygen() {
      return PERCENTAGES[this.moleculeNumber].O;
    },
  },
};
</script>
